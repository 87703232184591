<script>
import storable from '~/mixins/storable'

export default {
  mixins: [storable('recent', 'dealers.recent')],
  data: () => ({
    recentMax: 5,
    loading: true,
    value: null,
    recent: []
  }),
  computed: {
    dealerNodes: ({ $auth }) => $auth.user?.dealers?.nodes || [],
    dealers ({ dealerNodes, groups }) {
      return dealerNodes.map(({ id, name }) => ({ id, name }))
        .concat(groups)
        .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    },
    groups: ({ dealerNodes }) => Object.values(dealerNodes.reduce((groups, { group }) => {
      if (group) {
        // start counting how many dealerships are in the group
        groups[group.id] ??= { count: 0, group }
        groups[group.id].count++
      }
      return groups
    }, {}))
      // filter out any group with less than 2 dealerships
      .filter(({ count }) => count > 1)
      // add an `isGroup` flag to help identify groups in the dealers list
      .map(({ group: { id, name } }) => ({ id, name, isGroup: true })),

    items () {
      if (!this.recent.length) return this.dealers
      return [
        { type: 'subheader', name: 'Recent' },
        ...this.recent,
        { type: 'divider' },
        { type: 'subheader', name: 'All' },
        ...this.dealers
      ]
    }
  },
  watch: {
    '$route.meta.enterprise' () {
      this.setRecent()
    }
  },
  mounted () {
    this.setRecent()
    this.loading = false
  },
  methods: {
    setRecent () {
      const isGroup = this.$route.meta.enterprise

      this.value = isGroup
        ? {
            id: this.$auth.group.id,
            name: this.$auth.group.name,
            isGroup: true
          }
        : this.recent.filter(
          ({ id }) => id === String(this.$auth.dealer.id)
        )[0] || this.$auth.dealer

      this.saveRecent(this.value)
    },
    saveRecent ({ id, name, isGroup }) {
      if (this.dealers.length <= this.recentMax) return

      // remove the dealer if it already exists in recent
      this.recent = this.recent.filter(r => r.id !== id)

      // add the new dealer to the top
      this.recent.unshift({ id, name, isGroup, avoidSearch: true })

      // trim the length to max recent length
      this.recent = this.recent.slice(0, this.recentMax)
    },
    /**
     * Switches the dealer or group based on the selected item
     * @param {Object|null} dealerData - information defined by setRecent
     * @returns {undefined|void} - returns early when cleaning up the input
     *                             or void by switching the dealer or group
     */
    selectDealer (dealerData) {
      if (dealerData === null) return
      const { id, name, isGroup } = dealerData
      this.saveRecent({ id, name, isGroup })
      if (!isGroup) return this.$auth.switchDealer(id, { targetPath: '/dashboard' })

      this.$auth.switchDealerGroup(id, name, '/enterprise/dashboard')
    },
    handleSearch (itemText, queryText, item) {
      if (item.props.avoidSearch) return false
      return itemText.toLowerCase().includes(queryText.toLowerCase())
    }
  }
}
</script>

<template>
  <VAutocomplete
    data-id="dealer-autocomplete"
    :items="items"
    :loading="loading"
    :model-value="value"
    class="v-autocomplete--dealer"
    item-title="name"
    :item-props="true"
    label="Dealership"
    density="compact"
    hide-details
    variant="outlined"
    :custom-filter="handleSearch"
  >
    <template #item="{ item, props }">
      <VListSubheader
        v-if="item.raw.type==='subheader'"
        class="v-autocomplete--dealer__item"
        :title="item.value"
      />
      <VDivider v-else-if="item.raw.type==='divider'" />

      <VListItem
        v-else-if="item.raw.isGroup"
        v-bind="{...props, title: ''}"
        class="v-autocomplete--dealer__item cursor-pointer"
        :value="item.value"
        @click="selectDealer(item.raw)"
      >
        <VListItemTitle class="text-subtitle-2">
          {{ item.value }}
        </VListItemTitle>
        <template #append>
          <VChip
            label
            size="x-small"
          >
            group
          </VChip>
        </template>
      </VListItem>
      <VListItem
        v-else
        active-class="text-primary"
        v-bind="{...props, title: ''}"
        class="v-autocomplete--dealer__item cursor-pointer"
        @click="selectDealer(item.raw)"
      >
        <VListItemTitle class="text-subtitle-2">
          {{ item.value }}
        </VListItemTitle>
      </VListItem>
    </template>
  </VAutocomplete>
</template>

<style lang="scss">
.v-autocomplete--dealer {
  max-width: fit-content;

  .v-autocomplete__menu-icon,
  .v-field__input {
    opacity: 1;
  }

  .v-input__control {
    min-width: 150px;
  }

  .v-autocomplete__selection-text {
    overflow: unset;
    text-overflow: unset;
  }

  &.v-autocomplete--active-menu .v-input__control,
  &.v-autocomplete--active-menu .v-autocomplete__selection {
    min-width: 250px;
  }
}

.v-autocomplete--dealer__item {
  &.v-list-subheader,
  &.v-list-item--density-default.v-list-item--one-line {
    min-height: 40px;
  }
}
</style>
